<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Glucemia</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              variant="primary"
              @click="
                showModal('add', {
                  date: new Date().toISOString().substr(0, 10),
                  quantity: 0,
                })
              "
            >
              Agregar
            </b-button>
          </template>
          <template v-slot:body>
            <DataTable
              v-if="glycemia"
              :data="sortedData"
              :columns="columns"
              :fields-search="['description']"
            >
              <template #rows="{ row, rowIndex }">
                <tr>
                  <th scope="row">{{ rowIndex + 1 }}</th>
                  <td>
                    {{ row.date }}
                  </td>
                  <td>
                    {{ row.quantity }}
                  </td>
                  <td>
                    <b-badge v-if="row.result === 'NORMAL'" variant="success">
                      {{ row.result }}
                    </b-badge>
                    <b-badge v-else-if="row.result === 'PREDIABETES'" variant="warning">
                      {{ row.result }}
                    </b-badge>
                    <b-badge v-else variant="danger">{{ row.result }}</b-badge>
                  </td>
                  <td>
                    <b-button-group>
                      <b-button
                        variant="warning"
                        size="sm"
                        @click="showModal('edit', row)"
                      >
                        <i class="ri-ball-pen-fill m-0"></i>
                      </b-button>
                      <b-button variant="danger" size="sm" @click="destroy(row.id)">
                        <i class="ri-delete-bin-line m-0"></i>
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
              </template>
            </DataTable>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <ModalForm @getData="getData" :type="type" :data="data" />
  </b-container>
</template>

<script>
import { Toast } from './../../../mixins/toast'
import { xray } from '../../../config/pluginInit'
import DataTable from './../../../components/DataTable.vue'
import ModalForm from './ModalForm.vue'
import Swal from 'sweetalert2'
import api from '../../../services/api'

export default {
  components: {
    ModalForm,
    DataTable
  },
  data () {
    return {
      data: {},
      type: 'add',
      glycemia: [],
      columns: [
        {
          name: 'Fecha',
          field: 'date',
          sort: ''
        },
        {
          name: 'Cantidad',
          field: 'quantity',
          sort: ''
        },
        {
          name: 'Resultado',
          field: 'result',
          sort: ''
        }
      ]
    }
  },
  mounted () {
    xray.index()
    this.getData()
  },
  computed: {
    sortedData () {
      return this.glycemia.slice().sort((a, b) => new Date(b.date) - new Date(a.date))
    }
  },
  methods: {
    async getData () {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const { data } = await api.get(`/patients/${user.patient_id}`)
        this.glycemia = data.data.glucose
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    showModal (type, data) {
      this.data = data
      this.type = type
      this.$bvModal.show('modal-form')
    },
    destroy (id) {
      const user = JSON.parse(localStorage.getItem('user'))
      Swal.fire({
        icon: 'warning',
        title: 'Seguro de eliminar',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/patients/${user.patient_id}/glucose/${id}`)
            .then((res) => {
              if (res.status === 200) {
                Toast.fire({
                  icon: 'success',
                  title: 'Eliminado correctamente'
                })
                this.getData()
              }
            })
            .catch((error) => {
              Toast.fire({
                icon: 'error',
                title: error.message
              })
            })
        }
      })
    }
  }
}
</script>
