<template>
  <b-modal
    id="modal-form"
    :title="type === 'add' ? 'Agregar' : 'Editar'"
    no-close-on-backdrop
    hide-footer
  >
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-form-group class="col-md-12" label="Fecha:" label-for="date">
            <ValidationProvider name="Fecha" rules="required" v-slot="{ errors }">
              <b-form-input
                v-model="form.date"
                type="date"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-12" label="Cantidad:" label-for="quantity">
            <ValidationProvider name="Cantidad" rules="required" v-slot="{ errors }">
              <b-form-input
                v-model="form.quantity"
                type="number"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <div class="col-md-12 w-100">
            <b-button type="submit" variant="primary" class="float-right">
              {{ type === "add" ? "Guardar" : "Actualizar" }}
            </b-button>
          </div>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { Toast } from './../../../mixins/toast'
import api from './../../../services/api'
import Swal from 'sweetalert2'

export default {
  components: {},
  props: {
    type: {
      type: String,
      required: true,
      default: 'add'
    },
    data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      form: {
        quantity: 0,
        date: ''
      }
    }
  },
  watch: {
    data: function (newValue, oldValue) {
      if (this.type === 'edit') {
        newValue.date = this.formatDate(newValue.date)
      }
      this.form = newValue
    }
  },
  methods: {
    onSubmit () {
      if (this.type === 'add') {
        this.saveData()
      } else {
        this.updateData()
      }
    },
    async saveData () {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        await api.post(`/patients/${user.patient_id}/glucose`, this.form)

        this.showAlert(this.form.quantity)

        this.$bvModal.hide('modal-form')
        this.$emit('getData')
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    showAlert (quantity) {
      if (quantity < 100) {
        Swal.fire(
          'Normal',
          '¡Excelente sigue así! 😁',
          'success'
        )
      } else if (quantity <= 125) {
        Swal.fire(
          '¡Prediabetes!',
          '¡Estás en buen tiempo para mejorar! 🙂',
          'warning'
        )
      } else {
        Swal.fire(
          '¡Diabetes!',
          '¡Atención, debes tener mucho cuidado! 😔',
          'error'
        )
      }
    },
    async updateData () {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        await api.put(`/patients/${user.patient_id}/glucose`, this.form)

        this.showAlert(this.form.quantity)

        this.$bvModal.hide('modal-form')
        this.$emit('getData')
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    formatDate (date) {
      const partes = date.split('-')
      const year = partes[0]
      const month = partes[1]
      const day = partes[2].length === 1 ? `0${partes[2]}` : partes[2]
      return `${year}-${month}-${day}`
    }
  }
}
</script>
